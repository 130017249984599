import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Bookmark from '../views/bookmark/bookmark.vue';
import Logs from '../views/logs/logs.vue';
import NotFound from '../views/NotFound.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/bookmark',
    name: 'bookmark',
    component: Bookmark,
    meta: {
      requireLoginState: true,
    }
  },
  {
    path: '/logs',
    name: 'logs',
    component: Logs,
    meta: {
      requireLoginState: true,
    }
  },
  {
    path: '*',
    name: 'notFound',
    component: NotFound,
    meta: {
      requireLoginState: false,
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // 判定权限:只有登录之后才能访问simi页面
  let isLogin = window.sessionStorage.getItem('store') && JSON.parse(window.sessionStorage.getItem('store')).uid //假设当前非登录状态
  // 如果要前往的页面需要登录状态, 并且 当前登录状态是假
  if (to.meta.requireLoginState && !isLogin) {
    next('/');
    return;
  }
  else if (to.name === 'logs' && JSON.parse(window.sessionStorage.getItem('store')).uname !== 'Yannis') {
    next({ name: 'notFound' })
    return
  }
  // 设置title
  // document.title = to.meta.title

  // next():调用之后才能前往到下一个页面
  next();
})

export default router
