<template>
  <div class="bookmark">
    <div class="main w100 pos-rel">
      <div class="bg vw100 vh100 fx-center al-center mg-0 pd-0 over-hiden">
        <canvas
          id="canvas"
          class="canvas pos-abs-left-top"></canvas>
      </div>
      <div class="continer w100 h100 fx-col-start al-center pos-abs-left-top">
        <header
          class="text-center"
          @mouseover="showmenu()"
          @mouseout="closemenu()">
          {{ chooseMenu }}
          <div
            class="select"
            @click="choose($event)"
            v-show="showSelect && menu.length">
            <div
              v-for="(item, index) of menu"
              :key="index"
              class="item col-fff f20 fw-400">
              {{ item }}
            </div>
            <div class="arrow"></div>
          </div>
        </header>
        <main>
          <div
            class="group"
            v-for="(item, index) of bookmark"
            :key="index">
            <div class="title">{{ item.type }}</div>
            <div class="detail">
              <el-popover
                class="text-center"
                v-for="item in item.data"
                :key="item.id"
                placement="top-start"
                :title="item.name"
                width="200"
                trigger="hover"
                :content="item.comment"
                :open-delay="500">
                <div
                  slot="reference"
                  class="item btn"
                  @click="open(item)">
                  <div class="name limited-one">{{ item.name }}</div>
                  <div
                    class="set"
                    @click.stop="set(item)">
                    <i
                      class="iconfont icon-shezhi"
                      @click.stop="set(item)"></i>
                  </div>
                </div>
              </el-popover>
            </div>
          </div>
          <div class="group">
            <div class="title">其它</div>
            <div class="detail">
              <div
                class="add text-center"
                v-if="admin"
                @click="show_log">
                查看日志
              </div>
              <div
                class="add text-center"
                @click="add">
                <i
                  @click.stop="add"
                  class="iconfont icon-jiahao"></i>
              </div>
            </div>
          </div>
        </main>
        <div
          class="beian"
          style="margin: 0 auto; position: fixed">
          <a
            target="_blank"
            href="https://beian.miit.gov.cn/"
            style="display: inline-block; text-decoration: none">
            <img src="@/assets/images/beian.png" />
            <p style="color: #939393">蜀ICP备2021020289号-1</p>
          </a>
        </div>
      </div>
    </div>
    <set
      v-if="setData"
      :data.sync="setData"></set>
    <add v-if="addData"></add>
  </div>
</template>

<script>
import set from "./components/set.vue";
import add from "./components/add.vue";
import { throttle } from "@/assets/Utils/index";
export default {
  components: { set, add },
  data() {
    return {
      data: null, // 书签数据
      setData: null, // 进行更改的书签
      addData: false, // 是否显示新增组件
      showSelect: false, // 是否显示头部下拉选框
      chooseMenu: null, // 当前选中的书签类型
      allData: null, // 所有数据
      menu: [],
      loading: true, // 是否正在加载
      admin: false,
    };
  },
  computed: {
    bookmark() {
      // 先根据type进行分组
      let data = this.data;
      if (!data) {
        return [];
      }
      let result = new Array();
      result.push({
        type: "",
        data: [],
      });
      for (let i = 0; i < data.length; i++) {
        let temp = result.find((current, index) => {
          return current.type === data[i].type.trim();
        });
        if (temp) {
          result[result.indexOf(temp)].data.push(data[i]);
        } else {
          result.push({
            type: data[i].type.trim(),
            data: [data[i]],
          });
        }
      }
      result[0].data.length ? "" : result.shift();
      // 根据click_log进行降序排列
      return result.map((item, index) => {
        item.type = item.type === "" ? "未分类" : item.type;
        let data = item.data;
        let step = Math.floor(data.length / 2);
        do {
          for (let i = step; i < data.length; i++) {
            let temp = data[i];
            let j = i - step;
            for (j; j >= 0 && data[j].click_log.length < temp.click_log.length; j -= step) {
              data[j + step] = data[j];
            }
            data[j + step] = temp;
          }
          step = Math.floor(step / 2);
        } while (step > 0);
        return item;
      });
    },
  },
  created() {
    this.getData();
    this.admin = this.$store.state.uname === "Yannis";
  },
  mounted() {
    this.renderBg();
  },
  methods: {
    renderBg() {
      "use strict";

      let mouse, originx, originy, int, cvs;

      // Safari doesn't support EventTarget
      var EventTarget = EventTarget || false;

      // addEventListener shorthand
      if (EventTarget) {
        EventTarget.prototype.evt = function (event, callback) {
          return this.addEventListener(event, callback);
        };
      } else {
        window.evt = function (event, callback) {
          return this.addEventListener(event, callback);
        };
        document.evt = function (event, callback) {
          return this.addEventListener(event, callback);
        };
        Element.prototype.evt = function (event, callback) {
          return this.addEventListener(event, callback);
        };
      }

      // getElementById shorthand
      function $(elemId) {
        return document.getElementById(elemId);
      }

      function init() {
        cvs = $("canvas");

        resizeCanvas(cvs);

        window.evt("resize", resizeCanvas, false);
        window.evt("mousemove", function (e) {
          mouse = getMousePos(cvs, e);
          originx = mouse.x;
          originy = mouse.y;
        });
        // window.evt("touchmove", function (e) {
        //     originx = e.originalEvent.touches[0].pageX;
        //     originy = e.originalEvent.touches[0].pageY;
        // });

        var network = new Field(0, 0, 50);
        var emit = new Emitter(0, 0, 50);

        animateCanvas(cvs, function () {
          network.animate();
          emit.animate();
        });
      }

      // Individual particle
      class Point {
        constructor(x, y, canvas, dia) {
          this.canvas = canvas || cvs;
          this.x = x || 0;
          this.y = y || 0;
          this.vx = 0;
          this.vy = 0;
          this.speed = Math.random() * 0.5 + 0.2;
          this.angle = Math.random() * 360;
          this.diaSet = dia || 2 + Math.random() * 10;
          this.dia = this.diaSet;
          this.age = 0;
          let hue = Math.floor(Math.random() * 360);
          this.fill = "hsl(" + hue + ", 95%, 70%)";
          this.line = Math.random() > 0.5 ? true : false;
        }

        emit(life) {
          let s = this.speed * 2;
          this.angle += Math.random() * 10 - 5;
          this.x += s * Math.cos((this.angle * Math.PI) / 180);
          this.y += s * Math.sin((this.angle * Math.PI) / 180);
          this.age += 1 / life;
          this.boundary();
        }

        boundary() {
          if (this.x < 0) {
            this.x = this.canvas.width;
          }
          if (this.x > this.canvas.width) {
            this.x = 0;
          }
          if (this.y < 0) {
            this.y = this.canvas.height;
          }
          if (this.y > this.canvas.height) {
            this.y = 0;
          }
        }

        field(life) {
          let s = this.speed;
          this.angle += Math.random() * 10 - 5;
          this.x += s * Math.cos((this.angle * Math.PI) / 180);
          this.y += s * Math.sin((this.angle * Math.PI) / 180);
          this.age += 1 / life;
          this.boundary();
        }

        shrink(life) {
          this.dia = (1 - this.age) * this.diaSet;
        }

        draw() {
          let ctx = this.canvas.getContext("2d"),
            x = this.x,
            y = this.y,
            dia = this.dia,
            age = this.age;

          ctx.beginPath();
          ctx.fillStyle = this.fill;
          ctx.strokeStyle = this.fill;
          ctx.lineWidth = 2;
          ctx.arc(x, y, dia, 0, 2 * Math.PI);
          ctx.closePath();

          this.line !== true ? ctx.fill() : ctx.stroke();
        }
      }

      class ParticleGroup {
        setPosition(x, y) {
          this.x = x;
          this.y = y;
        }

        getPosition(x, y) {
          return {
            x: this.x,
            y: this.y,
          };
        }

        spawn(x, y, amount, dia) {
          var arr = [];
          dia = dia || false;

          amount = amount || 1;

          if (amount > 1) {
            for (let i = 0; i < amount; i++) {
              if (dia) {
                arr.push(new Point(x, y, cvs, dia));
              } else {
                arr.push(new Point(x, y, cvs));
              }
            }
          } else {
            arr = new Point(x, y, cvs, dia);
          }

          return arr;
        }
      }

      // Particle Emitter
      class Emitter extends ParticleGroup {
        constructor(x, y, life, mouse, dia) {
          super();

          if (mouse === undefined) {
            this.mouse = true;
          } else {
            this.mouse = mouse;
          }

          this.particles = [];
          this.x = x || 0;
          this.y = y || 0;
          this.life = life || 20;
          this.canvas = cvs;
          this.dia = dia || false;
        }

        animate() {
          let particles = this.particles;
          if (this.mouse) {
            this.setPosition(originx, originy);
          }

          let mul = 1;

          for (let i = 0; i < mul; i++) {
            particles.push(this.spawn(this.x, this.y, 1));
          }

          if (particles.length > this.life * mul) {
            for (let i = 0; i < mul; i++) {
              particles.shift();
            }
          }

          this.render(this.canvas);
        }

        render() {
          let life = this.life;
          let ctx = this.canvas.getContext("2d");
          let particles = this.particles;

          for (let i = 0; i < particles.length; i++) {
            const p = particles[i];
            p.draw();
            p.emit(this.life);
            p.shrink();
          }
        }
      }

      // Particle Field
      class Field extends ParticleGroup {
        constructor(x, y, life) {
          super();
          this.particles = [];
          this.canvas = cvs;
          this.x = x || 0;
          this.y = y || 0;
          this.life = life;

          for (let i = 0; i < this.life; i++) {
            let x = Math.random() * cvs.width,
              y = Math.random() * cvs.height;

            this.particles.push(this.spawn(x, y, 1));
          }
        }

        animate() {
          this.render(canvas);
        }

        render(canvas) {
          let ctx = this.canvas.getContext("2d");
          let particles = this.particles;

          for (let i = 0; i < particles.length; i++) {
            const p = particles[i];
            p.draw();
            p.field(this.life);
          }
        }
      }

      // get the mouse position relative to the canvas
      function getMousePos(cvs, evt) {
        const rect = cvs.getBoundingClientRect();
        return {
          x: evt.clientX - rect.left,
          y: evt.clientY - rect.top,
        };
      }

      // animate the canvas
      function animateCanvas(canvas, callback) {
        const ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        callback();

        requestAnimationFrame(animateCanvas.bind(null, canvas, callback));
      }

      // Update canvas size to fill window
      function resizeCanvas(canvas) {
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
        originx = canvas.width / 2;
        originy = canvas.height / 2;
      }

      init();
    },
    getData() {
      const loading = this.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
        customClass: "loaging",
      });
      this.axios
        .post("/bookmark/getBookmark", {
          authorization: localStorage.getItem("authorization"),
        })
        .then(res => {

          loading.close();
          if (res.data.code === 202) console.log("post_err:", res.data.msg);
          else {
            let data = res.data.result;
            this.allData = data;
            // 获取一级分类数组
            let menu = new Set();
            data.forEach(item => {
              menu.add(item.category);
            });
            menu = new Array(...menu);
            if (!menu.length) {
              // 没有任何数据时
              this.chooseMenu = "网络书签";
              this.data = data;
            } else if (menu.length > 1) {
              // 一级分类大于1时 如果已经有一级分类名,判断有没有数据,没有数据或者没有一级分类名
              // 将一级分类名设置为第一个一级分类
              if (this.chooseMenu) {
                if (
                  data.find(item => {
                    return item.category === this.chooseMenu;
                  })
                ) {
                  menu = menu.filter(item => {
                    return item !== this.chooseMenu;
                  });
                } else {
                  this.chooseMenu = menu[0];
                  menu.shift();
                }
              } else {
                this.chooseMenu = menu[0];
                menu.shift();
              }
              this.data = data.filter(item => {
                return item.category === this.chooseMenu;
              });
            } else if ((menu.length = 1)) {
              this.chooseMenu = menu[0];
              menu.shift();
              this.data = data;
            }
            this.menu = menu;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    open: throttle(function (data) {
      this.axios
        .post("/bookmark/click", {
          id: data.id,
          authorization: localStorage.getItem("authorization"),
        })
        .then(res => {
          if (res.data.code == 200) {
            for (let i = 0; i < this.data.length; i++) {
              if (this.data[i].id == data.id) {
                this.data[i].click_log = res.data.data;
              }
            }
            window.open(data.url, "_blank");
          }
        })
        .catch(err => {
          console.log(err);
        });
    }),
    set(data) {
      this.setData = data;
    },
    add() {
      this.addData = true;
    },
    // 查看日志
    show_log() {
      this.$router.push("/logs");
    },
    // 头部标题鼠标移入事件 显示下拉选框
    showmenu() {
      this.showSelect = true;
    },
    closemenu() {
      this.showSelect = false;
    },
    choose(e) {
      let choose = "";
      if (e) {
        if (e.target.innerHTML.trim().length) choose = e.target.innerHTML.trim();
        else return;
      } else {
        choose = this.chooseMenu;
      }
      let { menu } = this;
      menu.push(this.chooseMenu);
      this.chooseMenu = choose;
      this.menu = menu.filter(item => {
        return item !== choose;
      });
      this.data = this.allData.filter(item => {
        return item.category === choose;
      });
      1;
    },
    copy_file() {
      this.$router.push("/copyFile");
    },
  },
};
</script>

<style scope lang="less">
@media screen and (min-width: 768px) {
  .el-popover {
    border-radius: 10px;
    width: 500px !important;
    transform: translateY(6px);
  }
  .loaging {
    div > * {
      color: rgba(120, 35, 169) !important;
    }
    .el-icon-loading {
      font-size: 90px;
    }
    .el-loading-text {
      font-size: 30px !important;
    }
  }
  .bookmark {
    > .main {
      > .bg {
        background-color: #214;
        .canvas {
          pointer-events: none;
        }
      }
      > .continer {
        padding: 20px;
        > header {
          font-size: 60px;
          padding-bottom: 20px;
          width: 400px;
          height: 400px;
          > .select {
            border-radius: 20px;
            top: 90px;
            width: 300px;
            > .item {
              height: 50px;
              line-height: 50px;
            }
            > .arrow {
              border: 20px solid;
              top: -38px;
            }
          }
        }
        > main > .group {
          > .title {
            font-size: 25px;
            padding-bottom: 10px;
            padding-top: 10px;
          }
          > .detail {
            max-width: 1382px;
            grid-template-columns: repeat(6, 1fr);
            display: grid;
            gap: 10px;
            justify-content: center;
            .item {
              height: 50px;
              border-radius: 50px;
              border: 2px solid #f0f0f0;
              font-size: 20px;
              line-height: 50px;
              > .set {
                width: 50px;
                height: 50px;
                > i {
                  font-size: 20px;
                }
              }
            }
            .add {
              height: 50px;
              border-radius: 50px;
              border: 2px solid #f0f0f0;
              font-size: 20px;
              line-height: 50px;
              > i {
                font-size: 25px;
              }
            }
          }
        }
        .beian {
          width: 250px;
          padding: 20px 0;
          right: 10px;
          bottom: 10px;
          > a {
            height: 20px;
            line-height: 20px;
            display: flex !important;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;
            > img {
              display: block;
            }
            > p {
              display: block;
              height: 20px;
              line-height: 20px;
              margin: 0px 0px 0px 5px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .el-popover {
    border-radius: 1.3333vw;
    width: 66.6667vw !important;
    transform: translateY(.8vw);
  }
  .loaging {
    div > * {
      color: rgba(120, 35, 169) !important;
    }
    .el-icon-loading {
      font-size: 12vw;
    }
    .el-loading-text {
      font-size: 4vw !important;
    }
  }
  .bookmark {
    > .main {
      > .bg {
        background-image: url("https://www.fantasyfuturex.com:5000/data/images/bg6.png");
        background-repeat: no-repeat;
        background-position: top left;
        background-size: 100% 100%;
        .canvas {
          display: none;
        }
      }
      > .continer {
        padding: 2.6667vw;
        > header {
          font-size: 8vw;
          padding-bottom: 2.6667vw;
          width: 53.3333vw;
          height: 53.3333vw;
          > .select {
            border-radius: 2.6667vw;
            top: 12vw;
            width: 40vw;
            > .item {
              height: 6.6667vw;
              line-height: 6.6667vw;
            }
            > .arrow {
              border: 2.6667vw solid;
              top: -5.0667vw;
            }
          }
        }
        > main {
          padding-bottom: 8vw;
          > .group {
            > .title {
              font-size: 3.3333vw;
              padding-bottom: 1.3333vw;
              padding-top: 1.3333vw;
            }
            > .detail {
              grid-template-columns: repeat(3, 1fr);
              display: grid;
              gap: 1.3333vw;
              justify-content: center;
              .item {
                height: 6.6667vw;
                border-radius: 6.6667vw;
                border: 0.2667vw solid #f0f0f0;
                font-size: 2.6667vw;
                line-height: 6.6667vw;
                > .set {
                  width: 6.6667vw;
                  height: 6.6667vw;
                  > i {
                    font-size: 2.6667vw;
                  }
                }
              }
              .add {
                height: 6.6667vw;
                border-radius: 6.6667vw;
                border: 0.2667vw solid #f0f0f0;
                font-size: 2.6667vw;
                line-height: 6.6667vw;
                > i {
                  font-size: 3.3333vw;
                }
              }
            }
          }
        }
        .beian {
          padding: 2.6667vw 0;
          right: 2.6667vw;
          bottom: 2.6667vw;
          font-size: 2.1333vw;
          > a {
            height: 2.6667vw;
            line-height: 2.6667vw;
            display: flex !important;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;
            > img {
              width: 2.6667vw;
              height: 2.6667vw;
              display: block;
            }
            > p {
              display: block;
              height: 2.6667vw;
              line-height: 2.6667vw;
              margin: 0px 0px 0px 0.6667vw;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}

.el-popover {
  background-color: rgba(120, 35, 169, 0.8) !important;
  color: #d0d0d0 !important;
  white-space: pre-wrap;
  .el-popover__title {
    color: #fff !important;
  }
}
.bookmark {
  height: 100vh;
  width: 100vw;

  > .main {
    > .continer {
      user-select: none;
      overflow-y: scroll;
      > header {
        font-weight: 600;
        background-image: url("/public/images/iron.png");
        background-size: 100% 100%;
        color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
        position: relative;
        z-index: 1;
        > .select {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          background-color: rgba(120, 35, 169, 0.8);
          display: flex;
          flex-flow: column nowrap;
          align-items: center;
          justify-content: flex-start;
          > .item {
            color: #fff;
            width: 100%;
          }
          > .arrow {
            width: 0px;
            height: 0px;
            border-color: transparent;
            border-bottom-color: rgba(120, 35, 169, 0.8);
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
      > main > .group {
        > .title {
          color: #bc008d;
        }
        > .detail {
          .item {
            width: 100%;
            color: #ee5d5c;
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;
            > .name {
              width: 100%;
            }
            > .set {
              flex-shrink: 0;
              > i {
                color: white;
              }
            }
          }
          .item:hover {
            background-color: rgba(#b2cdf6, 0.3);
          }
          .add {
            color: #ee5d5c;
            > i {
              color: white;
            }
          }
        }
      }
    }
  }
}
</style>
