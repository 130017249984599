import {
  Popover,
  Input,
  Tree,
  Image,
  Form,
  FormItem,
  Button,
  Message,
  MessageBox,
  Autocomplete,
  Upload,
  Loading,
  Checkbox,
} from "element-ui";
const installElement = function (Vue) {
  Vue.use(Popover);
  Vue.use(Tree);
  Vue.use(Input);
  Vue.use(Image);
  Vue.use(Form);
  Vue.use(FormItem);
  Vue.use(Button);
  Vue.use(Autocomplete);
  Vue.use(Upload);
  Vue.use(Checkbox);
  Vue.use(Loading.directive);
  Vue.prototype.$loading = Loading.service;
  Vue.prototype.$message = Message;
  Vue.prototype.$confirm = MessageBox.confirm;
};
export default installElement;
