//去除谷歌的touch事件警告
(function () {
  if (typeof EventTarget !== "undefined") {
    let func = EventTarget.prototype.addEventListener;
    EventTarget.prototype.addEventListener = function (type, fn, capture) {
      this.func = func;
      if (typeof capture !== "boolean") {
        capture = capture || {};
        capture.passive = false;
      }
      this.func(type, fn, capture);
    };
  }
})();
import "default-passive-events";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "/public/css/gloab.css";
import installElement from "./assets/element_ui";
import { Api } from "@/mixin/index";
Api.install.call(Vue);
installElement(Vue);
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app");
