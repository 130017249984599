<template>
  <div
    class="fixed-toast-container"
    @mousewheel.stop.prevent>
    <div class="set">
      <header class="fx-sb">
        <div class="title col-000 fw-600 f24">修改书签</div>
        <i
          class="iconfont icon-exit f32 col-red"
          @click="exit()"></i>
      </header>
      <main>
        <el-form
          v-if="detail"
          :model="detail"
          status-icon
          :rules="rules"
          label-suffix="："
          ref="form"
          label-width="100px">
          <el-form-item
            label="书签名称"
            prop="name">
            <el-input
              @keyup.enter.native="submitForm('form')"
              v-model="detail.name"></el-input>
          </el-form-item>
          <el-form-item
            label="链接"
            prop="url">
            <el-input
              @keyup.enter.native="submitForm('form')"
              v-model="detail.url"></el-input>
          </el-form-item>
          <el-form-item
            label="一级分类"
            prop="_class">
            <el-autocomplete
              disabled
              class="inline-input"
              v-model="detail._class"
              :fetch-suggestions="querySearch_"
              placeholder="请输入书签类型"
              @select="handleSelect"></el-autocomplete>
          </el-form-item>
          <el-form-item
            label="二级分类"
            prop="type">
            <el-autocomplete
              class="inline-input"
              v-model="detail.type"
              :fetch-suggestions="querySearch"
              placeholder="请输入书签类型"
              @select="handleSelect"></el-autocomplete>
          </el-form-item>
          <el-form-item
            label="备注"
            prop="comment">
            <el-input
              type="textarea"
              :autosize="{ maxRows: 5 }"
              resize="none"
              v-model="detail.comment"
              placeholder="请输入书签备注"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="danger"
              @click="del()"
              >删除</el-button
            >
            <el-button
              type="primary"
              @click="submitForm('form')"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </main>
    </div>
  </div>
</template>

<script>
import { throttle } from "@/assets/Utils/index";
export default {
  data() {
    return {
      group: [], // 书签分组数据
      detail: null,
      _class: null, // 书签一级类目

      rules: {
        name: [
          {
            type: "string",
            whitespace: true,
            required: true,
            message: "请输入书签名称!",
            trigger: "blur",
          },
        ],
        url: [
          {
            type: "url",
            required: true,
            message: "请输入正确的网络地址!",
            trigger: "blur",
          },
        ],
        _class: [
          {
            type: "string",
            required: true,
            whitespace: true,
            message: "请输入一级分类!",
            trigger: "clange",
          },
        ],
      },
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleSelect() {},
    querySearch(queryString, cb) {
      let group = this.detail._class ? (this.detail._class.length ? this.group[this.detail._class] : []) : [];
      group = group ? group : [];
      var results = queryString
        ? group.filter(item => {
            return item.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
          })
        : group;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    querySearch_(queryString, cb) {
      let _class = this._class;
      let results = queryString
        ? _class.filter(item => {
            return item.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
          })
        : _class;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    exit() {
      this.$emit("update:data", null);
    },
    submitForm: throttle(function (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let data = new Object();
          for (let [key, value] of Object.entries(this.detail)) {
            if (key !== "id" && key !== "uid" && key !== "click_log") {
              data[key] = value.trim();
            } else data[key] = value;
          }
          let authorization = localStorage.getItem("authorization");
          this.axios
            .post("/bookmark/alter", { authorization, data })
            .then(res => {
              if (res.data.code === 200) {
                this.$emit("update:data", null);
                this.$parent.getData();
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
              } else {
                this.$emit("update:data", null);
                console.log(res);
                this.$message.error("修改失败");
              }
            })
            .catch(err => {
              console.log(err);
              this.$message.error("修改失败");
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }),
    del: throttle(function () {
      this.$confirm("此操作将永久删除该书签, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let authorization = localStorage.getItem("authorization");
          this.axios
            .post("/bookmark/del", { authorization, data: this.detail })
            .then(res => {
              if (res.data.code === 200) {
                this.$emit("update:data", null);
                this.$parent.getData();
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
              } else {
                console.log(res);
                this.$message.error("删除失败");
              }
            })
            .catch(err => {
              this.$message.error("删除失败");
              console.log(err);
            });
        })
        .catch(() => {
          this.$emit("update:data", null);
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    }),
  },
  mounted() {
    let detail = new Object();
    for (let [key, value] of Object.entries(this.$props.data)) {
      detail[key] = value;
    }
    this.detail = detail;
    this.axios.get("/bookmark/group").then(res => {
      if (res.data.code === 200) {
        let data = res.data.data;
        this.group = data;
        this._class = Object.keys(data).map(item => {
          return { value: item };
        });
      } else {
        this.group = {};
      }
    });
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 768px) {
  .set {
    width: 600px;
    border-radius: 20px;
    padding: 20px;
    > header {
      padding-bottom: 20px;
    }
    > main {
      width: 500px;
      ::v-deep(.el-form) {
        label {
          font-size: 15px;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .set {
    width: 94.6667vw;
    border-radius: 2.6667vw;
    padding: 2.6667vw;
    > header {
      padding-bottom: 2.6667vw;
    }
    > main {
      width: 89.3333vw;
      ::v-deep(.el-form) {
        label {
          font-size: 2vw;
        }
      }
    }
  }
}
.set {
  background-color: white;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  > header {
    width: 100%;
  }
  > main {
    ::v-deep(.el-form) {
      label {
        font-weight: 600;
        color: #333;
      }
      .el-autocomplete {
        width: 100%;
      }
      > .el-form-item:last-child {
        margin: 0;
        > .el-form-item__content {
          display: flex;
          flex-flow: row nowrap !important;
          justify-content: space-between !important;
          .el-button {
            margin: 0;
          }
          &::after,
          &::before {
            display: none;
          }
        }
      }
    }
  }
}
</style>
